import i18n from "@/libs/i18n";

const fields = [
    {
        key: 'title',
        label: i18n.t('title'),
    },
    {
        key: 'slug',
        label: i18n.t('slug'),
    },
    {
        key: 'action',
        label: i18n.t('action'),
    },
]

export default fields